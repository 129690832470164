const initialState = {
    msg: "",
    closeIcon: true,
    toast: false,
    type: "error",
    name: {},
};

const modal = (state = initialState, { type, payload }) => {
    switch (type) {
        case "TOGGLE_MODAL":
            return {
                ...state,
                name: {
                    ...state.name,
                    [payload.name]: payload.state,
                },
            };
        case "TOGGLE_TOAST":
            return {
                ...state,
                msg: payload.msg,
                toast: payload.state,
                closeIcon: payload.closeIcon,
                type: "error",
            };
        case "SET_MSG":
            return {
                ...state,
                msg: payload.msg
            }
        default:
            return state;
    }
};

export default modal;
