export const toggleModal =
    ({ name, state = false, closeIcon = true, parentId = false }) =>
        (dispatch) => {
            dispatch({
                type: "TOGGLE_MODAL",
                payload: {
                    name,
                    state,
                    closeIcon,
                    parentId
                },
            });
        };

export const toggleToast =
    ({ state = false, msg = "" }) =>
        (dispatch) => {
            dispatch({
                type: "TOGGLE_TOAST",
                payload: {
                    state,
                    msg,
                },
            });
        };

export const setMsg = (msg) => ({
    type: "SET_MSG",
    payload: {
        msg,
    },
});