import axios from "axios";

import {
    err_msg,
    getAllUrlParam,
    removeParamUrl,
    // fetchCss
} from "helper";
import { catch_error, get_info, get_translate } from "./main";
const ls = window.localStorage;

export const set_param = (data) => ({
    type: "SET_PARAM",
    data,
});

export const set_token = () => {
    return (dispatch, getState) => {
        const { auth } = getState();
        const params = getAllUrlParam();
        let param = Object.keys(params).length === 0 ? auth?.param : params;

        if (param?.access_token || param?.gop_access_token) {
            dispatch(getJWT(param))
                .then(() => {
                    /** Call api checklogin or info */
                    dispatch(get_info(true));
                    dispatch(set_param(param));
                })
                .catch((err) => {
                    dispatch(get_translate());
                    dispatch(catch_error(err_msg(err)));
                });
        } else {
            /** Handle error , show modal or toast error */
            dispatch(get_translate());
            dispatch(catch_error("not_logged_in"));
            return;
        }
    };
};

export const getJWT = (param) => {
    return () => {
        return axios.get(`oauth/callback/`, { params: param }).then((resp) => {
            removeParamUrl();
            ls.setItem("token", resp.data.token);
            axios.defaults.headers[process.env.REACT_APP_TOKEN_HEADER_NAME] =
                resp.data.token;
        });
    };
};
