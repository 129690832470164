export const DEFAULT_LANG = "EN";

/**
 * NOTE You need to confirm the default parameters with the back-end
 * If there is no region or region error, return US_en directly.
 * If it is the default language, return region, otherwise return region_lang
 * @param {*} region
 * @param {*} lang
 */
export const getRegionLang = (region = "US", lang = "es") => {
  const regionMap = {
    BR: {
      default: "pt",
      other: [],
    },
    SAC: {
      default: "es",
      other: [],
    },
    VN: {
      default: "vn",
      other: [],
    },
    SG: {
      default: "en",
      other: [],
    },
    RU: {
      default: "ru",
      other: [],
    },
    IND: {
      default: "en",
      other: [],
    },
    ME: {
      default: "",
      other: ["ar", "fr", "en"],
    },
    TW: {
      default: "zh-tw",
      other: ["ja", "ko"], // Here in order to cater to the background, the corresponding is transify
    },
    TH: {
      default: "th",
      other: ["en"],
    },
    US: {
      default: "es",
      other: [],
    },
    ID: {
      default: "id",
      other: [],
    },
    EU: {
      default: "",
      other: ["tr", "en"],
    },
    EUROPE: {
      default: "",
      other: ["tr", "en"],
    },
    NA: {
      default: "",
      other: ["es", "en"],
    },
  };
  let regionLang = "";
  if (
    regionMap[region].default === lang ||
    regionMap[region].other.indexOf(lang) < 0
  ) {
    if (region === "ME") {
      // ME area is ME_ar except ME_fr
      regionLang = "ME_ar";
    } else {
      regionLang = region === "EU" ? "EUROPE_en" : region;
    }
  } else {
    regionLang = region === "EU" ? `EUROPE_${lang}` : `${region}_${lang}`;
  }
  return regionLang;
};
