import React, { lazy, Suspense, useEffect } from "react";
import { Routes, BrowserRouter, Route } from "react-router-dom";
import { ErrorBoundary } from "@sentry/react";
import { useDispatch, useSelector } from "react-redux";
// Component

import ViewportRestrict from "helper/component/viewport";
import Loader from "helper/component/loader";
import NotFound from "helper/component/404";
import LoadingScreen from "helper/component/loadingscreen";
import Fallback from "helper/component/fallback";
import { componentLoader } from "helper";

import { translate } from "redux/actions/main";
import { set_token } from "redux/actions/auth";

// Pages
const Home = lazy(() => componentLoader(() => import("pages/Home")));

export const Main = () => {
  const { loader } = useSelector((state) => state?.main);
  const dispatch = useDispatch();
  const t = (key) => dispatch(translate(key));

  useEffect(() => {
    dispatch(set_token());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
      <div id="Main" className="main-panel">
        <ErrorBoundary fallback={Fallback}>
          <Suspense fallback={<LoadingScreen />}>
            <ViewportRestrict
              display={true}
              type="portrait"
              text={t("txt_viewport_restrict")}
            />
            <Loader loader={loader} />
            <div className="content-container">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
          </Suspense>
        </ErrorBoundary>
      </div>
    </BrowserRouter>
  );
};

export default Main;
