const main_state = {
	login: false,
	loader: false,
	lang_json: false,
	confirm_type: false,
	modal_data: false,
	isSpinning: false,
	draw_result: false,
	claim_result: false,
	modal_message: false,

	items: [],
  buildset: false,
};

const main = (state = main_state, action) => {
	switch (action.type) {
		case "PUT_DATA":
			return { ...state, [action.key]: action.data };
		case "TOGGLE_LOADER":
			return { ...state, loader: action.data };
		case "TOGGLE_LOADER_HASH":
			return { ...state, loader_hash: action.data };
		default:
			return state;
	}
};

export default main;
