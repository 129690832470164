import axios from "axios";
import { err_msg, generateCSSVariable, getLsObject, preload } from "helper";
import { setMsg, toggleModal } from "./modal";
import { format } from "date-fns";
import { DEFAULT_LANG } from "helper/lang";
import { setAnalytics } from "helper/default";

const trapp = axios.create({
    baseURL: `https://webid.cdn.garenanow.com/gstaticid/trapp/`,
    withCredentials: false,
});

export const toggle_loader = (data) => ({
    type: "TOGGLE_LOADER",
    data: data,
});

export const put_data = (key, data) => ({
    type: "PUT_DATA",
    key,
    data,
});

const combineObjects = (responses, lang) =>
    responses.reduce((acc, curr) => {
        let result = { ...curr.data[lang || DEFAULT_LANG], ...acc };
        if (
            process.env.REACT_APP_LOCALIZE_NAME?.includes("codm") &&
            !curr.data[lang || DEFAULT_LANG]
        ) {
            result = { ...curr.data, ...result };
        }
        return result;
    }, {});

export const get_translate = (region) => {
    return (dispatch) => {
        let lang = region || DEFAULT_LANG;
        if (region === "SM" || region === "MS" || region === "PH") {
            lang = "EN";
        }
        const main_localize = trapp.get(
            `${process.env.REACT_APP_LOCALIZE_NAME}.json?${Math.random() * 10000}`,
        );
        const common_localize = trapp.get(
            `tr_common.json?${Math.floor(Math.random() * 10000)}`,
        );

        axios
            .all([common_localize, main_localize])
            .then((resp) => {
                const transformed = combineObjects([resp[0], resp[1]], lang);
                generateCSSVariable(transformed)
                dispatch(put_data("lang_json", transformed));
                preload(transformed, "img_")
            })
            .catch((err) => { });
    };
};

export const catch_error = (err_msg) => {
    return (dispatch) => {
        if (err_msg) {
            dispatch(setMsg(err_msg));
        }

        dispatch(
            toggleModal({
                name: "error",
                state: true,
            }),
        );

        dispatch(toggle_loader(false));

        if (err_msg === "invalid_region") {
            localStorage.clear();
        }
    };
};

export const translate = (key) => {
    return (dispatch, getState) => {
        const { lang_json } = getState().main;
        if (lang_json[key]) {
            return lang_json[key];
        } else {
            return key;
        }
    };
};

export const get_info = (shouldShowModal = false) => {
    return (dispatch, getState) => {
        dispatch(toggle_loader(true));

        axios
            .get("api/info/")
            .then((resp) => {
                const { user, event } = resp?.data?.data;
                const { region, open_id, can_join, has_joined } = user;
                const { is_voting_period_ended } = event;

                dispatch(get_translate(region));
                setAnalytics(region, user.open_id)

                dispatch(put_data("user", user));
                document.body.setAttribute("data-lang", region.toLowerCase());

                let consentId = getLsObject("consent-" + open_id);
                if (region === "TH" && !consentId) {
                    dispatch(toggleModal({ name: "consent", state: true }));
                }

                const hasShownInfoForFirstTime = getLsObject("first");
                if (!hasShownInfoForFirstTime) {
                    dispatch(
                        toggleModal({
                            name: "eventinfo",
                            state: true,
                        }),
                    );
                } else {
                    if (can_join) {
                        dispatch(toggleModal({ name: "chooseside", state: true }));
                    } else if (has_joined) {
                        const tickDate = getLsObject("tickDate");
                        const shouldShowPopup = tickDate !== format(new Date(), "d-M");
                        if (shouldShowPopup) {
                            dispatch(put_data("modal_message", "msg_announcement_event"));
                            dispatch(toggleModal({ name: "message", state: true }));
                        }
                    }
                }

                dispatch(put_data("info", event));

                if (is_voting_period_ended && shouldShowModal) {
                    dispatch(put_data("modal_message", "draw_after_close"));
                    dispatch(toggleModal({ name: "message", state: true }));
                }
            })
            .catch((err) => {
                dispatch(get_translate());
                setAnalytics()
                dispatch(catch_error(err_msg(err)));
            })
            .then(() => {
                dispatch(toggle_loader(false));
            });
    };
};

export const post_select = () => {
    return (dispatch, getState) => {
        dispatch(toggle_loader(true));
        const { buildset } = getState().main;

        axios
            .post("api/select/", buildset)
            .then((resp) => {
                dispatch(get_info());
                dispatch(toggleModal({ name: "confirmation", state: false }));
                dispatch(toggleModal({ name: "buildset", state: false }));
            })
            .catch((err) => {
                dispatch(catch_error(err_msg(err)));
            })
            .then(() => {
                dispatch(toggle_loader(false));
            });
    };
};

export const post_draw = (draw_cost) => {
    return (dispatch, getState) => {
        dispatch(toggle_loader(true));

        axios
            .post("/api/draw/", { draw_cost: draw_cost })
            .then((resp) => {
                dispatch(put_data("draw_result", resp.data.data));
            })
            .catch((err) => {
                dispatch(catch_error(err_msg(err)));
            })
            .then(() => {
                dispatch(toggle_loader(false));
            });
    };
};

export const post_claim = () => {
    return (dispatch, getState) => {
        dispatch(toggle_loader(true));

        axios
            .post("/api/claim/")
            .then((resp) => {
                dispatch(put_data("claim_result", resp.data.data));
                dispatch(toggleModal({ name: "keepyourspirit", state: true }));
                dispatch(get_info());
            })
            .catch((err) => {
                dispatch(catch_error(err_msg(err)));
            })
            .then(() => {
                dispatch(toggle_loader(false));
            });
    };
};

export const post_reset = () => {
    return (dispatch, getState) => {
        dispatch(toggle_loader(true));

        axios
            .post("api/reset/")
            .then((resp) => {
                dispatch(get_info());
            })
            .catch((err) => {
                dispatch(catch_error(err_msg(err)));
            })
            .then(() => {
                dispatch(toggle_loader(false));
            });
    };
};
